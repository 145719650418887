import React, {Component} from 'react';

import { IoIosPhonePortrait } from "react-icons/io";


class PhoneListComponent extends Component{



    render(){
        if(this.props.user.businessPhones && this.props.user.businessPhones.length && this.props.user.mobilePhone){
            if(this.props.user.businessPhones[0] === this.props.user.mobilePhone){
                return(
                    <small className={"smallTest"}>
                        <IoIosPhonePortrait/>
                        <a href={"tel:" + this.props.user.mobilePhone}>
                            {this.props.user.mobilePhone}
                        </a>
                    </small>
                )
            } else{
                return(
                    <div>
                        <small className={"smallTest"}>
                            <IoIosPhonePortrait/>
                            <a href={"tel:" + this.props.user.businessPhones[0]}>
                                {this.props.user.businessPhones[0]}
                            </a>
                        </small>
                        <small className={"smallTest"}>
                            <IoIosPhonePortrait/>
                            <a href={"tel:" + this.props.user.mobilePhone}>
                                {this.props.user.mobilePhone}
                            </a>
                        </small>
                    </div>
                )
            }
        }else if(this.props.user.businessPhones && this.props.user.businessPhones.length){
            return(
                <small className={"smallTest"}>
                    <IoIosPhonePortrait/>
                    <a href={"tel:" + this.props.user.businessPhones[0]}>
                        {this.props.user.businessPhones[0]}
                    </a>
                </small>
            )
        } else if( this.props.user.mobilePhone !== null){
            return(
                <small className={"smallTest"}>
                    <IoIosPhonePortrait/>
                    <a href={"tel:" + this.props.user.mobilePhone}>
                        {this.props.user.mobilePhone}
                    </a>
                </small>
            )
        } else{
            return(null)
        }
    }
}

export default PhoneListComponent;