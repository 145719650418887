const actionTypes = {
    projects: {
        GET_PROJECTS_SUCCESS: "GET_PROJECTS_SUCCESS",
        GET_PROJECTS_FAILED: "GET_PROJECTS_FAILED"
    },
    employees: {
        GET_EMPLOYEES_SUCCESS: "GET_EMPLOYEES_SUCCESS",
        GET_EMPLOYEES_FAILED: "GET_EMPLOYEES_FAILED",

        UPDATE_EMPLOYEE_IMAGE_SUCCESS: "UPDATE_EMPLOYEE_IMAGE_SUCCESS",
        UPDATE_EMPLOYEE_IMAGE_FAILED: "UPDATE_EMPLOYEE_IMAGE_FAILED",

        GET_EMPLOYEES_FUNCTIONS: "GET_EMPLOYEES_FUNCTIONS",
        GET_EMPLOYEES_DEPARTMENTS: "GET_EMPLOYEES_DEPARTMENTS",
        GET_EMPLOYEES_LOCATIONS: "GET_EMPLOYEES_LOCATIONS",
        GET_LOGGEDIN_USER: "GET_LOGGEDIN_USER",
    }
};

export {
    actionTypes
}
