import {actionTypes} from "../constants/actionTypes";

const {projects} = actionTypes;

const initialState = {
    projects: null,
};

export default (state = initialState, action) => {
    switch(action.type){
        case projects.GET_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: action.payload
            };
        case projects.GET_PROJECTS_FAILED:
            return {...state};
        default:
            return state;
    }
}