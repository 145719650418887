import React, {Component} from 'react';

import ListGroup from "react-bootstrap/ListGroup";
import Img from 'react-image'
import { IoIosMail } from "react-icons/io";
import PhoneListComponent from "./PhoneListComponent";
import {apiRoutes} from "../../constants/apiRoutes";

class List extends Component{

    render(){
        return(
            <ListGroup className={"scrollable"}>
                {this.props.filteredValues
                    .sort((a,b) => a.user.displayName > b.user.displayName ? 1: -1)
                    .map((item, index) => (
                        <ListGroup.Item
                            onClick={() => this.props.handleShow(item)}
                            key={index}
                        >
                            <div className={"listgroupCard"}>
                                <Img src={apiRoutes.apiUrl + apiRoutes.api.image + item.user.id} className={"cardimage"} width={120} />
                                <p> {item.user.displayName} </p>
                                <small>{item.user.jobTitle}</small> <br/>
                                <small class>{item.user.city}</small>
                                <small className={"smallTest"}>
                                    <IoIosMail/>
                                    <a href={"mailto:" + item.user.mail}>
                                        {item.user.mail}
                                    </a>
                                </small>
                                <PhoneListComponent user={item.user}/>


                            </div>
                        </ListGroup.Item>
                    ))}
            </ListGroup>
        )
    }
}

export default List;
