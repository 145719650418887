import React, {Component} from 'react';

import Table from "react-bootstrap/Table";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";

import filterStyle from '../../assets/filterStyle';
import Select from "react-select";

import { IoMdMenu } from "react-icons/io";


class MobileFilterTable extends Component{

    render(){
        return(
           <div>
               <div className={"filterbutton"}>
                   <Button
                       className={"filterbutton"}
                       onClick={e => {this.props.handleMobileFilterButton(!this.props.open)}}
                       aria-controls="example-collapse-text"
                       aria-expanded={true}
                   >
                       <IoMdMenu />
                   </Button>
               </div>

               <Collapse className={"filterbutton"} in={this.props.open}>
                   <Table size="sm" borderless>
                       <tbody>
                       <tr>
                           <td>Vestiging:</td>
                           <td>
                               <Select
                                   isMulti
                                   name={"officeLocation"}
                                   placeholder={"Selecteer vestiging"}
                                   options={this.props.officeLocations}
                                   className={"basis-multi-select"}
                                   classNamePrefix={"select"}
                                   closeMenuOnSelect={false}
                                   onChange={this.props.handleMulti}
                                   styles={filterStyle}
                               />
                           </td>
                       </tr>
                       <tr>
                           <td>Functie:</td>
                           <td>
                               <Select
                                   isMulti
                                   name={"userFunction"}
                                   placeholder={"Selecteer functie"}
                                   options={this.props.userFunctions}
                                   className={"basis-multi-select"}
                                   classNamePrefix={"select"}
                                   closeMenuOnSelect={false}
                                   onChange={this.props.handleMulti}
                                   styles={filterStyle}
                               />
                           </td>
                       </tr>
                       <tr>
                           <td>Unit:</td>
                           <td>
                               <Select
                                   isMulti
                                   name={"department"}
                                   placeholder={"Selecteer unit"}
                                   options={this.props.departments}
                                   className={"basis-multi-select"}
                                   classNamePrefix={"select"}
                                   closeMenuOnSelect={false}
                                   onChange={this.props.handleMulti}
                                   styles={filterStyle}
                               />
                           </td>
                       </tr>
                       </tbody>
                   </Table>
               </Collapse>
           </div>
        )
    }
}

export default MobileFilterTable;