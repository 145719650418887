import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import projectActions from '../../actions/projectsActions';
import employeeActions from "../../actions/employeesActions";

import UserModal from '../Users/UserModel';
import config from "../../config/Config";


class ProjectTable extends Component {

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);


        this.state = {
            show: false,
            selectedUser: {},
            projects: [],
            columns: [
                {
                    dataField: 'projectid',
                    text: 'Project ID',
                    sort: true,
                    filter: textFilter()
                },
                {
                    dataField: 'opdrachtgever',
                    text: 'Opdrachtgever',
                    sort: true,
                    filter: textFilter()
                },
                {
                    dataField: 'omschrijving',
                    text: 'Omschrijving',
                    sort: true,
                    filter: textFilter()
                },
                {
                    dataField: 'adres',
                    text: 'Adres',
                    sort: true,
                    filter: textFilter()
                },
                {
                    dataField: 'projectleider',
                    text: 'Projectleider',
                    sort: true,
                    filter: textFilter()
                }
             ]
        };

    }

    async componentDidMount(){

        if(!this.props.projects.projects > 0){
            await this.props.projectActions.getProjects();
        }

        if(this.props.employees.employees && !this.props.employees.employees.length > 0){
            let accessToken = await window.msal.acquireTokenSilent({
                scopes: config.scopes
            });
            await this.props.employeeActions.testFunction(accessToken);
            this.props.employeeActions.testPhotoFunction(accessToken, this.props.employees.employees.sort((a,b) => a.user.displayName > b.user.displayName ? 1: -1));
        }


        this.setState({
            projects: this.props.projects.projects
        });
    }

    handleShow(user) {
        this.setState({
            show: true,
            selectedUser: user.user,
            selectedUserImage: user.image
        });
    }

    handleClose() {
        this.setState({ show: false });
    }

    render() {
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if(row.projectleider_email){
                    const foundIndex = this.props.employees.employees.findIndex(
                        user => user.user.mail.toLowerCase() === row.projectleider_email.toLowerCase()
                    );

                    const selected = this.props.employees.employees[foundIndex];
                    this.handleShow(selected)
                }
            }
        };

        return (
            <div>
                <BootstrapTable
                    striped
                    hover
                    keyField='id'
                    data={this.state.projects}
                    columns={ this.state.columns }
                    filter={filterFactory()}
                    pagination={ paginationFactory()}
                    size={"sm"}
                    className="react-bootstrap-table"
                    rowEvents={rowEvents}
                />
                <UserModal
                    selectedUser={this.state.selectedUser}
                    selectedUserImage={this.state.selectedUserImage}
                    show={this.state.show}
                    handleClose={this.handleClose}
                />
            </div>

        );
    }
}

const mapStateToProps = ({projects, employees}) => {
    return {
        projects,
        employees
    }
};

const mapDispatchToProps = dispatch => {
    return {
        projectActions: bindActionCreators(projectActions, dispatch),
        employeeActions: bindActionCreators(employeeActions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTable);
