import {actionTypes} from "../constants/actionTypes";

const {employees} = actionTypes;

const initialState = {
    employees: [],
    functions: [],
    departments: [],
    locations: []
};

export default (state = initialState, action) => {
    switch(action.type){
        case employees.GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.payload,
            };
        case employees.GET_EMPLOYEES_DEPARTMENTS:
            return {
                ...state,
                departments: action.payload,
            };
        case employees.GET_EMPLOYEES_FUNCTIONS:
            return {
                ...state,
                functions: action.payload,
            };
        case employees.GET_EMPLOYEES_LOCATIONS:
            return {
                ...state,
                locations: action.payload,
            };
        case employees.UPDATE_EMPLOYEE_IMAGE_SUCCESS:

            let employeesCopy = state.employees;

            const foundIndex = employeesCopy.findIndex(
                user => user.user.id === action.payload.user.id
            );

            employeesCopy[foundIndex] = action.payload;
            return {
                ...state,
                employees: employeesCopy
            };

        case employees.UPDATE_EMPLOYEE_IMAGE_FAILED:
            return {
                ...state,
            };
        case employees.GET_EMPLOYEES_FAILED:
            return {...state};
        default:
            return state;
    }
}