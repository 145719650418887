import React, {Component} from 'react';

import { IoIosPhonePortrait } from "react-icons/io";


class PhoneComponent extends Component{
    render(){

        if(this.props.selectedUser.businessPhones && this.props.selectedUser.businessPhones.length){
            if(this.props.selectedUser.businessPhones[0] === this.props.selectedUser.mobilePhone){
                return(
                    <div>
                        {this.props.selectedUser.mobilePhone !== null ?
                            <small className={"smallTest"}>
                                <IoIosPhonePortrait/>
                                Mobiel:
                                <a href={"tel:" + this.props.selectedUser.mobilePhone}>
                                    {this.props.selectedUser.mobilePhone}
                                </a>
                            </small> : ''
                        }
                    </div>
                )
            } else {
                return (
                    <div>
                        {this.props.selectedUser.mobilePhone !== null ?
                            <small className={"smallTest"}>
                                <IoIosPhonePortrait/>
                                Mobiel:
                                <a href={"tel:" + this.props.selectedUser.mobilePhone}>
                                    {this.props.selectedUser.mobilePhone}
                                </a>
                            </small> : ''
                        }
                        {this.props.selectedUser.businessPhones && this.props.selectedUser.businessPhones.length ?
                            <small className={"smallTest"}>
                                <IoIosPhonePortrait/>
                                Werk:
                                <a href={"tel:" + this.props.selectedUser.businessPhones}>
                                    {this.props.selectedUser.businessPhones}
                                </a>
                            </small> : ''
                        }
                    </div>
                )
            }
        } else {
            return(
                <div>
                    {this.props.selectedUser.mobilePhone !== null ?
                        <small className={"smallTest"}>
                            <IoIosPhonePortrait/>
                            Mobiel:
                            <a href={"tel:" + this.props.selectedUser.mobilePhone}>
                                {this.props.selectedUser.mobilePhone}
                            </a>
                        </small> : ''
                    }
                </div>
            )
        }
    }
}

export default PhoneComponent;