import axios from 'axios';
import {apiRoutes} from '../constants/apiRoutes';
let graph = require("@microsoft/microsoft-graph-client");

export default class ApiService {

    static ROOT_URL = apiRoutes.rootUrl;
    static API_URL = apiRoutes.apiUrl;

    static async performApiRequest(url, token){
        let response;
        response = await axios.get(ApiService.API_URL + url, {
            headers: {
                "Authorization" : "Bearer " + token
            }
        });

        return response.data;
    }

    static async performRequest(url){
        let response;
        response = await axios.get(ApiService.ROOT_URL + url, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }
        });
        return response.data;

    }

    static getAuthenticatedClient(accessToken) {
        // Initialize Graph client
        const client = graph.Client.init({
            // Use the provided access token to authenticate
            // requests
            authProvider: (done) => {
                done(null, accessToken.accessToken);
            }
        });

        return client;
    }

    static async getUsers(accessToken){
        let response;
        const client = this.getAuthenticatedClient(accessToken);

        response = await client.api(
            "/users?$top=999&$select=displayName,givenName,jobTitle,officeLocation,mail,businessPhones,mobilePhone,department,id,country,city&$filter=userType eq 'Member' and AccountEnabled eq true"
        ).get();
        return response;
    }

    static async getUserPhoto(accessToken, userID){
        let response;
        const client = this.getAuthenticatedClient(accessToken);

        try {
            response = await client
                .api("/users/" + userID + "/photo/$value")
                .responseType("blob")
                .get();
        }catch (e) {

        }

        return response;
    }
}
