import {apiRoutes} from "../constants/apiRoutes";
import {actionTypes} from "../constants/actionTypes";
import ApiService from "../Services/ApiService";

const {projects} = actionTypes;

const getProjects = () => {
    return async dispatch => {
        let url = apiRoutes.projects.getProjects,
            response = await ApiService.performRequest(url);
        dispatch({
            type: projects.GET_PROJECTS_SUCCESS,
            payload: response,
        })
    }
};

export default {
    getProjects
}