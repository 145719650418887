import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import UserList from "../../containers/Users/UserList";
import { Route } from 'react-router-dom'

import ProjectTable from "../../containers/Projects/ProjectTable"

class Dashboard extends Component {

  render() {
    return (
      <div className="App">

          <Row>
          <Col sm={1} md={1} lg={1} className={"sidebar"}>
            {/* <Sidebar /> */}
          </Col>

          <Col sm={10} md={10} lg={10} className={"userlist"}>
                  <div>
                      <Route exact path="/" component={UserList} />
                      <Route path="/projects" component={ProjectTable} />
                  </div>
          </Col>
        </Row>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Dashboard;
