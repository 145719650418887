import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";

import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Image from "react-bootstrap/Image"

import { Link } from 'react-router-dom';

import { IoIosPerson } from "react-icons/io";
import logo from "../../images/logo.bmp"

class Navigationbar extends Component {
  render() {
    function AuthNavItem(props) {
      if (props.isAuthenticated) {
        return (
                <DropdownButton
                    title={<IoIosPerson />}
                    id="dropdown-menu-align-left"
                    drop="left">
                    <Dropdown.Item disabled="false">
                        {" "}
                        {props.user.displayName}{" "}
                    </Dropdown.Item>
                    <Dropdown.Item disabled="false"> {props.user.email} </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={props.authButtonMethod}>
                        {" "}
                        Sign Out{" "}
                    </Dropdown.Item>
                </DropdownButton>
        );
      }

      return <Button onClick={props.authButtonMethod} variant={"primary"} className={"signInButton"}>Sign in</Button>;
    }

    function NavButtons(props){
        if(props.isAuthenticated) {
            return(
                <Nav className="mr-right">
                    <Link to="/">Collega's</Link>
                    <Link to="/projects">Projecten</Link>
                </Nav>
            )
        } else{
            return (null);
        }
    }

    return (
      <Navbar>
        <Navbar.Brand>
          <Image src={logo} width={50}/>
        </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <NavButtons
              isAuthenticated={this.props.isAuthenticated}
          />


        <Nav className="mr-auto" />
        <AuthNavItem
          isAuthenticated={this.props.isAuthenticated}
          authButtonMethod={this.props.authButtonMethod}
          user={this.props.user}
        />
      </Navbar>
    );
  }
}

export default Navigationbar;
