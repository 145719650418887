import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import employeeActions from '../../actions/employeesActions'

import config from "../../config/Config";

import FilterTable from './FilterTable';
import MobileFilterTable from './MobileFilterTable';
import Searchbar from './Searchbar';
import List from './List';
import UserModal from './UserModel';


class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMobileFilterButton = this.handleMobileFilterButton.bind(this);

    this.state = {
      selectedUser: {},
      filteredValues: [],
      show: false,
      officeUsers: [],
      officeLocations: [],
      userFunctions: [],
      departments: [],
      searchString: "",
      showFilter: true,
      open: true,
      value: [],
      selectedOptions: null
    };
  }

    async componentDidMount() {
        const { employeeActions } = this.props;

        let accessToken = await window.msal.acquireTokenSilent({
            scopes: config.scopes
        });

        if(!this.props.employees.employees.length > 0){
          await employeeActions.testFunction(accessToken);
          // employeeActions.testPhotoFunction(accessToken, this.props.employees.employees.sort((a,b) => a.user.displayName > b.user.displayName ? 1: -1));
        }

        this.setState({
        officeLocations: this.props.employees.locations.sort((a,b) => a.label > b.label ? 1: -1),
        filteredValues: this.props.employees.employees,
        officeUsers: this.props.employees.employees.sort((a,b) => a.label > b.label ? 1: -1),
        userFunctions: this.props.employees.functions.sort((a,b) => a.label > b.label ? 1: -1),
        departments: this.props.employees.departments.sort((a,b) => a.label > b.label ? 1: -1)
    });
  }

  filterUsers() {
    let locations = [],
        functions = [],
        departments = [];

    this.state.officeLocations.forEach(location => {
      if (location.isChecked) locations.push(location.label);
    });

    this.state.departments.forEach(department => {
      if (department.isChecked) departments.push(department.label);
    });

    this.state.userFunctions.forEach(item => {
      if (item.isChecked) functions.push(item.label);
    });

    let filters = {
      jobTitle: functions,
      officeLocation: locations,
      department: departments
    };

    let filtered = this.multiFilter(this.state.officeUsers, filters);

    this.setState({
      filteredValues: filtered
    });
  }

  multiFilter(array, filters) {
    const filterKeys = Object.keys(filters);

    array = array.filter(item =>
      item.user.displayName
        .toLowerCase()
        .includes(this.state.searchString.toLowerCase())
    );

    return array.filter(item => {
      return filterKeys.every(key => {
        if (!filters[key].length) return true;
        return filters[key].includes(item.user[key]);
      });
    });
  }

  updateSearch = event => {
    this.setState(
      {
        searchString: event.target.value
      },
      () => {
        this.filterUsers();
      }
    );
  };

  handleClose() {
    this.setState({ show: false });
  }

  handleMobileFilterButton(state) {
    this.setState({open: state})
  }

  handleShow(user) {
    this.setState({
      show: true,
      selectedUser: user.user,
      selectedUserImage: user.image
    });
  }

  clearInput = () => {
    this.setState({
        searchString: ''
    },
    () => {
        this.filterUsers();
    }
    );
  };

  handleMulti = (newValue, actionMeta) => {

    if(actionMeta.action === "remove-value"){
      if(actionMeta.name === "officeLocation") {
        let officeLocations = [...this.state.officeLocations];
        const foundIndex = officeLocations.findIndex(
            officeLocation => officeLocation.label === actionMeta.removedValue.label
        );
        officeLocations[foundIndex].isChecked = false;

        this.setState({
          ...this.state,
          officeLocations
        });
      }

      if (actionMeta.name === "userFunction") {
        let userFunctions = [...this.state.userFunctions];
        const foundIndex = userFunctions.findIndex(
            userFunction => userFunction.label === actionMeta.removedValue.label
        );
        userFunctions[foundIndex].isChecked = false;

        this.setState({
          ...this.state,
          userFunctions
        });
      }

      if (actionMeta.name === "department") {
        let departments = [...this.state.departments];
        const foundIndex = departments.findIndex(
            department => department.label === actionMeta.removedValue.label
        );
        departments[foundIndex].isChecked = false;

        this.setState({
          ...this.state,
          departments
        });
      }

    }

    if(actionMeta.action === "clear"){
      if(actionMeta.name === "officeLocation") {
        let officeLocations = [...this.state.officeLocations];
        officeLocations.map((a) => a.isChecked = false);
        this.setState({
          ...this.state, officeLocations
        });
      }
      if(actionMeta.name === "department"){
        let departments = [...this.state.departments];
        departments.map((a) => a.isChecked = false);
        this.setState({
          ...this.state, departments
        });
      }
      if(actionMeta.name === "userFunction") {
        let userFunctions = [...this.state.userFunctions];
        userFunctions.map((a) => a.isChecked = false);
        this.setState({
          ...this.state, userFunctions
        });
      }
    }

    if(actionMeta.action === "select-option"){
      if (actionMeta.option.type === "officeLocation") {
        let officeLocations = [...this.state.officeLocations];
        const foundIndex = officeLocations.findIndex(
          officeLocation => officeLocation.label === actionMeta.option.label
        );
        officeLocations[foundIndex].isChecked = !actionMeta.option.isChecked;

        this.setState({
          ...this.state,
          officeLocations
        });
      }

      if (actionMeta.option.type === "userFunction") {
        let userFunctions = [...this.state.userFunctions];
        const foundIndex = userFunctions.findIndex(
          userFunction => userFunction.label === actionMeta.option.label
        );
        userFunctions[foundIndex].isChecked = !actionMeta.option.isChecked;

        this.setState({
          ...this.state,
          userFunctions
        });
      }

      if (actionMeta.option.type === "department") {
        let departments = [...this.state.departments];
        const foundIndex = departments.findIndex(
          department => department.label === actionMeta.option.label
        );
        departments[foundIndex].isChecked = !actionMeta.option.isChecked;

        this.setState({
          ...this.state,
          departments
        });
      }
    }

     this.filterUsers();
  };

  render() {
      const { selectedUser, selectedUserImage, filteredValues } = this.state;

    return (
      <div>
        <Row>
          <Col sm={11} md={11} lg={11}>

              <FilterTable
                  userFunctions={this.state.userFunctions}
                  officeLocations={this.state.officeLocations}
                  departments={this.state.departments}
                  handleMulti={this.handleMulti}
              />

              <MobileFilterTable
                  userFunctions={this.state.userFunctions}
                  officeLocations={this.state.officeLocations}
                  departments={this.state.departments}
                  handleMulti={this.handleMulti}
                  open={this.state.open}
                  handleMobileFilterButton={this.handleMobileFilterButton}
              />

          </Col>

          <Col sm={11} md={11} lg={11}>
            <div className={"displayLength"}>
              <p>
                {filteredValues.length} of the{" "}
                {this.props.employees.employees.length} users
              </p>
            </div>

              <Searchbar
                  searchString={this.state.searchString}
                  clearInput={this.clearInput}
                  updateSearch={this.updateSearch}
              />

              <List
                  filteredValues={this.state.filteredValues}
                  handleShow={this.handleShow}
              />

              <UserModal
                selectedUser={selectedUser}
                selectedUserImage={selectedUserImage}
                show={this.state.show}
                handleClose={this.handleClose}
              />

          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({employees}) => {
  return {
    employees,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    employeeActions: bindActionCreators(employeeActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
