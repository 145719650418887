import React, {Component} from 'react';

import Button from "react-bootstrap/Button";

import FormControl from "react-bootstrap/FormControl";

import Form from "react-bootstrap/Form";
import {MdRemoveCircleOutline} from "react-icons/md";


class Searchbar extends Component{
    render(){
        return(
            <div>
                <Form className={"searchBar"}>
                    <FormControl
                        type="text"
                        placeholder="Zoeken op naam"
                        value={this.props.searchString}
                        className="mr-sm-2"
                        onChange={this.props.updateSearch}
                    />
                </Form>
                <Button
                    className={"nextTo"}
                    onClick={this.props.clearInput}
                ><MdRemoveCircleOutline/></Button>
            </div>
        )
    }
}

export default Searchbar;