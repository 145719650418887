import React  from "react";
import "./App.scss";

import { UserAgentApplication } from "msal";

import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Image from 'react-bootstrap/Image'
import Button from "react-bootstrap/Button";

import config from "./config/Config";
import Navigationbar from "./containers/Layout/Navigationbar";
import Dashboard from "./containers/Users/Dashboard";

import louvelsLogo from "./images/LOUVELS.png"
import appleLogo from "./images/app-store-logo.png";
import playStoreLogo from "./images/play_store.png";

import store from '../src/store';
import {Provider} from 'react-redux';

import projectActions from './actions/projectsActions';
import employeeActions from './actions/employeesActions';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import ApiService from "./Services/ApiService";
import {apiRoutes} from "./constants/apiRoutes";
import firebase from "./config/Firebase";

class AppComponent extends React.Component {
  constructor(props) {
    super(props);
      this.userAgentApplication = new UserAgentApplication({
          auth: {
              clientId: config.appId,
              authority: "https://login.microsoftonline.com/common",
          }
      });

      let user =  this.userAgentApplication.getAccount();

      this.state = {
          isAuthenticated: user !== null,
          user: {},
          error: null
      };
      if (user) {
        this.getUserProfile();
      }
  }

  render() {
    let error = null;
    if (this.state.error) {
      error = <Alert variant="danger"> {this.state.error.message} </Alert>;
    }

    const { isAuthenticated } = this.state;

    return (
      <div className="App">
        <Navigationbar
          isAuthenticated={this.state.isAuthenticated}
          user={this.state.user}
          authButtonMethod={
            this.state.isAuthenticated
              ? this.logout.bind(this)
              : this.login.bind(this)
          }
        />

        {error}

          {
              isAuthenticated ? <Dashboard isAuthenticated={isAuthenticated} demo={[]} /> :
                  <div className={"signinCard"}>
                      <Card style={{ width: "25rem" }}>
                          <Card.Img variant="top" src={louvelsLogo} />
                          <Card.Body>
                              <Card.Title>Louvels voor Constructif</Card.Title>
                              <Card.Text>
                                  Log in met Office365 door op de "Sign in" knop te drukken.
                              </Card.Text>
                              <Card.Text>
                                  <strong> Let op: </strong>gebruik als email domein <strong> @constructif.nl </strong>ipv <strong>@gdkw.nl</strong>
                              </Card.Text>
                          </Card.Body>
                      </Card>
                      <Card style={{ width: "25rem" }}>
                          <Card.Body>
                              <Card.Text>
                                  <Button className={"buttonicons"} href="https://apps.apple.com/nl/app/constructif-app/id1470370811"> <Image src={appleLogo} width={"160px"} /> </Button>
                                  <Button className={"buttonicons"} href="https://play.google.com/store/apps/details?id=com.louvels&hl=nl"> <Image src={playStoreLogo} width={"175px"} /> </Button>
                              </Card.Text>
                          </Card.Body>
                      </Card>
                  </div>

          }

      </div>
    );
  }

  async login() {
    try {
        firebase.setCurrentScreen("login");

        await this.userAgentApplication.loginPopup({
          scopes: config.scopes,
          prompt: "select_account",
          authority: "https://login.microsoftonline.com/common",
      });
      await this.getUserProfile();
    } catch (err) {
      let errParts = err.split("|");
      this.setState({
        isAuthenticated: false,
        user: {},
        error: { message: errParts[1], debug: errParts[0] }
      });
    }
  }

  logout() {
    this.userAgentApplication.logout();
  }

  async getUserProfile() {
      try {
      let accessToken = await this.userAgentApplication.acquireTokenSilent({
          scopes: config.scopes
      });

      if (accessToken) {

          let user = await ApiService.performApiRequest(apiRoutes.api.auth, accessToken.accessToken);

          if("1874f7b1-bf55-4d03-99be-85d2eb7f46cf" === user.organization.apiId ||
            "91ed1faa-4b74-4d57-aab5-bc35795425e8" === user.organization.apiId ||
            "68bfd232-3469-40fb-94e7-94922414067a" === user.organization.apiId
          ) {
              firebase.setUserId(user.id);
              firebase.setUserProperties({organisation : user.organization.id});

              this.props.employeeActions.postLoggedInUser(user);
              ApiService.performApiRequest(apiRoutes.api.updateUser + user.organization.id, accessToken.accessToken);

              this.setState({
                  isAuthenticated: true,
                  user: {
                      displayName: user.displayName,
                      email: user.mail || user.userPrincipalName
                  },
                  error: null,
                  status: user.organization.status
              });
          } else {
              this.setState({
                  isAuthenticated: false,
                  user: {},
                  error: { message: "Wrong organisation"}
              });
        }
      }

    } catch (err) {
      console.log(err);
        let error = {};
        if (typeof(err) === 'string') {
           let errParts = err.split('|');
            error = errParts.length > 1 ?
                { message: errParts[1], debug: errParts[0] } :
                { message: err };
        } else {
            error = {
                message: err.message,
                debug: JSON.stringify(err)
            };
        }

      this.setState({
        isAuthenticated: false,
        user: {},
        error: error
      });
    }
  }
}

const mapStateToProps = ({projects, employees}) => {
    return {
        projects,
        employees
    }
};

const mapDispatchToProps = dispatch => {
    return {
        projectActions: bindActionCreators(projectActions, dispatch),
        employeeActions: bindActionCreators(employeeActions, dispatch)
    }
};

const AppRouterContainer = connect(mapStateToProps, mapDispatchToProps)(AppComponent);

const App = () => (
  <Provider store={store}>
    <AppRouterContainer/>
  </Provider>
);

export default App;
