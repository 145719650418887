const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused
            ? '#e95a0c'
            : base.borderColor,
        '&:hover': {
            borderColor: state.isFocused
                ? '#e95a0c'
                : base.borderColor,
        }
    }),
    option: (styles, state) => ({
        ...styles,
        color: state.isSelected ? "#FFF" : styles.color,
        backgroundColor: state.isSelected ? "#ff8d3f" : styles.color,
        "&:hover": {
            color: "#FFF",
            backgroundColor: "#ff8d3f"
        }
    }),
};

export default customStyles;