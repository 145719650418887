import 'react-app-polyfill/ie11';
import 'babel-polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';

const routing = (
    <Router>
        <App/>
    </Router>
);

ReactDOM.render( routing, document.getElementById('root'));

serviceWorker.unregister();