import { actionTypes } from '../constants/actionTypes';
import ApiService from "../Services/ApiService";

const getUsers = (accessToken) => {
    return async dispatch => {
        let response = await ApiService.getUsers(accessToken);
        let users = [];
        let officeLocations = [];
        let userFunctions = [];
        let departments = [];

        response.value.forEach(item => {
            if (item.department !== 'filtered' && item.displayName !== "On-Premises Directory Synchronization Service Account") {
                users.push({
                    user: item,
                    image: null
                });

                if(item.officeLocation){
                    if(!officeLocations.some(item2 => item2.label === item.officeLocation)) {
                        officeLocations.push({
                            label: item.officeLocation,
                            value: item.officeLocation,
                            type: "officeLocation",
                            isChecked: false
                        })
                    }
                }

                if(item.jobTitle) {
                    if(!userFunctions.filter(e => e.label.toLowerCase() === item.jobTitle.toLowerCase()).length > 0) {
                        userFunctions.push({
                            label: item.jobTitle,
                            value: item.jobTitle,
                            type: "userFunction",
                            isChecked: false
                        });
                    }
                }

                if(item.department){
                    if(!departments.filter(e => e.label === item.department).length > 0){
                        departments.push({
                            label: item.department,
                            value: item.department,
                            type: "department",
                            isChecked: false
                        });
                    }
                }
            }
        });

        dispatch({
            type: actionTypes.employees.GET_EMPLOYEES_SUCCESS,
            payload: users
        });
        dispatch({
            type: actionTypes.employees.GET_EMPLOYEES_LOCATIONS,
            payload: officeLocations
        });
        dispatch({
            type: actionTypes.employees.GET_EMPLOYEES_DEPARTMENTS,
            payload: departments
        });
        dispatch({
            type: actionTypes.employees.GET_EMPLOYEES_FUNCTIONS,
            payload: userFunctions
        });
    }
};

const getUsersPhotos = (accessToken, employees) => {

    return async dispatch => {
        await employees.map(async  item => {
          let image = null,
              imageURL = null,
              isNull = false;

          try {
              image = await ApiService.getUserPhoto(accessToken, item.user.id);
              imageURL = window.URL.createObjectURL(image);
          } catch (error){
              isNull = true;
          }

          if(!isNull){
              item.image = imageURL;
              dispatch({
                  type: actionTypes.employees.UPDATE_EMPLOYEE_IMAGE_SUCCESS,
                  payload: item
              })
          }
        });
  }
};

const postLoggedInUser = (user) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.employees.GET_LOGGEDIN_USER,
            payload: user
        })
    }
};

export default {
    testFunction: getUsers,
    testPhotoFunction: getUsersPhotos,
    postLoggedInUser
}
