import React, {Component} from 'react';
import Table from "react-bootstrap/Table";
import filterStyle from '../../assets/filterStyle';
import Select from "react-select";


class FilterTable extends Component{
    render(){
        return(
            <Table size="sm" borderless className={"filtercollapse"}>
                <tbody>
                <tr>
                    <td>Vestiging</td>
                    <td>Functie</td>
                    <td>Unit</td>
                </tr>
                <tr>
                    <td className={"tableselect"}>
                        <Select
                            isMulti
                            name={"officeLocation"}
                            placeholder={"Selecteer vestiging"}
                            options={this.props.officeLocations}
                            className={"basis-multi-select"}
                            classNamePrefix={"select"}
                            closeMenuOnSelect={false}
                            onChange={this.props.handleMulti}
                            styles={filterStyle}
                        />
                    </td>
                    <td className={"tableselect"}>
                        <Select
                            isMulti
                            name={"userFunction"}
                            placeholder={"Selecteer functie"}
                            options={this.props.userFunctions}
                            className={"basis-multi-select"}
                            classNamePrefix={"select"}
                            closeMenuOnSelect={false}
                            onChange={this.props.handleMulti}
                            styles={filterStyle}
                        />
                    </td>
                    <td className={"tableselect"}>
                        <Select
                            isMulti
                            name={"department"}
                            placeholder={"Selecteer unit"}
                            options={this.props.departments}
                            className={"basis-multi-select"}
                            classNamePrefix={"select"}
                            closeMenuOnSelect={false}
                            onChange={this.props.handleMulti}
                            styles={filterStyle}
                        />
                    </td>
                </tr>
                </tbody>
            </Table>
        )
    }
}

export default FilterTable;