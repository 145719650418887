const apiRoutes = {
    rootUrl: 'https://constructiftest.louvels.com/api/',
    apiUrl: "https://api.louvels.com/index.php/api/",

    projects: {
        getProjects: 'Projects.php'
    },

    api: {
        auth: "user/info",
        image: "user/image/1874f7b1-bf55-4d03-99be-85d2eb7f46cf/",
        updateUser: "organization/updateData/"
    }

};

export {
    apiRoutes
}
