// import * as firebase from 'firebase';
// import 'firebase/'
//
// import firebase from "firebase/app";
// import 'firebase/analytics';

import * as firebase from 'firebase/app';
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAjJAjoQdZ48n5fI7hqoGW4vjc9ZpMA-c8",
    authDomain: "louvels.firebaseapp.com",
    databaseURL: "https://louvels.firebaseio.com",
    projectId: "louvels",
    storageBucket: "louvels.appspot.com",
    messagingSenderId: "464833208026",
    appId: "1:464833208026:web:1004a93f56b16d01400fe3",
    measurementId: "G-FTW0QLHSCF"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics().setAnalyticsCollectionEnabled(true);
firebase.analytics().setUserId();
// firebase.analytics().logEvent('start_game');
// firebase.analytics().logEvent('start_game', { level: '10', difficulty: 'expert' })

export default firebase.analytics();
