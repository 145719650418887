import React, {Component} from 'react';

import Modal from "react-bootstrap/Modal";
import {IoIosMail } from "react-icons/io";
import Image from "react-bootstrap/Image";

import PhoneComponent from "./PhoneComponent";
import {apiRoutes} from "../../constants/apiRoutes";

class UserModel extends Component{

    render(){
        return(
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.selectedUser.displayName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image
                        className={"cardimage"}
                        src={apiRoutes.apiUrl + apiRoutes.api.image + this.props.selectedUser.id}
                        width={175}
                    />
                    <small className={"smallTest"}>
                        <IoIosMail/>
                        <a href={"mailto:" + this.props.selectedUser.mail}>
                            {this.props.selectedUser.mail}
                        </a>
                    </small>

                    <PhoneComponent selectedUser={this.props.selectedUser}/>

                    {this.props.selectedUser.jobTitle !== null ?
                        <small className={"smallTest"}>
                            {"Functie: " + this.props.selectedUser.jobTitle}
                        </small> : ''
                    }

                    {this.props.selectedUser.officeLocation !== null ?
                        <small className={"smallTest"}>
                            {"Locatie: " + this.props.selectedUser.officeLocation}
                        </small> : ''
                    }

                    {this.props.selectedUser.department !== null ?
                        <small className={"smallTest"}>
                            {"Unit: " + this.props.selectedUser.department}
                        </small> : ''
                    }
                    {this.props.selectedUser.city !== null ?
                        <small className={"smallTest"}>
                            {this.props.selectedUser.city}
                        </small> : ''
                    }
                    <br/>
                </Modal.Body>
            </Modal>
        )
    }
}

export default UserModel;
